'use client';

import {
  Tabs as _Tabs,
  TabList as _TabList,
  Tab as _Tab,
  TabPanel as _TabPanel,
  TabListProps,
  TabPanelProps,
  TabProps,
  TabsProps,
} from 'react-aria-components';

import styles from './Tabs.module.scss';

export function Tabs({ className, ...props }: TabsProps) {
  return <_Tabs className={styles.tabs} {...props} />;
}

export function TabList({ className, ...props }: TabListProps<object>) {
  return <_TabList className={styles.tabList} {...props} />;
}

export function Tab({ className, ...props }: TabProps) {
  return <_Tab className={styles.tab} {...props} />;
}

export function TabPanel({ className, ...props }: TabPanelProps) {
  return <_TabPanel className={styles.tabPanel} {...props} />;
}
